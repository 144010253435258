import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Edview from './misc/Edview'

function AppRouter() {
 
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Edview/>}/>
      </Routes>
    </Router>
  )
}

export default AppRouter