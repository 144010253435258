import React from 'react'
import Content from '../layout/Content'
import { Button } from '@progress/kendo-react-buttons';
import { InputClearValue, TextBox } from "@progress/kendo-react-inputs";
import { Icon } from "@progress/kendo-react-common";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import axios from 'axios';
import {useAuth} from "../context/Auth"
import { TileLayout } from "@progress/kendo-react-layout";

const EMPTY_VALUE = "";
const Edview = () => {
  const {user, setTokenValid} = useAuth();
  const loadingPanel = (
    <div className="k-loading-mask center edview">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );
  const [edProfileData, setEdProfileData] = React.useState([]);
  const [edemailData, setEdEmailData] = React.useState([]);
  const [value, setValue] = React.useState(EMPTY_VALUE);
  const [searching, setSearching] = React.useState(false);
  const handleChange = React.useCallback((event) => {
    setValue(event.target.value);
    setEdProfileData([]);
    setEdEmailData([]);
  }, []);
  const handleClear = React.useCallback(() => {
    setValue(EMPTY_VALUE);
    setEdProfileData([]);
    setEdEmailData([]);
  }, []);
  const handleSearch = (e) => {
    e.preventDefault();
    setSearching(true)
    let config = {
      method: 'post',
      url: process.env.REACT_APP_ED_SEARCH_HOST + '/studentadminservice',
      headers: { 
        'Authorization': 'Bearer '+user.origToken, 
        'Content-Type': 'text/plain'
      },
      data : { "uid": value}
    };
    return axios(config)
      .then(response => {
        let profile = []
        let emailsAndPrivacy = [];
        if(response && response.data && response.data.success){
          for(let key in response.data.resData.data){
            if(key!='businessMessages'){
              if(key.toLowerCase().indexOf('email')>0 || (key+"Privacy") in response.data.resData.data){
                let privacy = '';
                if((key+"Privacy") in response.data.resData.data){
                  privacy = response.data.resData.data[key+"Privacy"]
                }
                else if(response.data.resData.data[key.split('Email')[0]+"Privacy"]){
                  privacy = response.data.resData.data[key.split('Email')[0]+"Privacy"]
                }
                else if(key.indexOf('bOL')>-1){
                  privacy = response.data.resData.data['bOLPrivacy']
                }
                emailsAndPrivacy.push({'Email Type':key, 'Email': response.data.resData.data[key], 'Privacy': privacy})
              }
              else if(key.toLowerCase().indexOf('privacy')<0){
                let fieldName = (key=='givanName')?'givenName':key;
                profile.push({'Name':fieldName, 'Value': response.data.resData.data[key]})
              }
            }
          } 
        }
        else if(response && response.data && !response.data.success && response.data.resCode==401){
          window.location.href = process.env.REACT_APP_ACCESS_DENIED_URL
        }
        setSearching(false);
        setEdProfileData(profile)
        setEdEmailData(emailsAndPrivacy)
      })
      .catch(error => {
        setSearching(false);
        console.log(error);
        setTokenValid(false)
      })    
  };
  const [data, setData] = React.useState([
    {
      col: 1,
      colSpan: 1,
      rowSpan: 1,
    },
    {
      col: 2,
      colSpan: 1,
      rowSpan: 1,
    }
  ]);
  const tiles = [
    {
      header: "Profile",
      body: (<Grid 
        data={edProfileData} 
        pageable={false}
        sortable={true}>
        <GridColumn field="Name" />
        <GridColumn field="Value" />
      </Grid>),
    },
    {
      header: "Emails & Privacy",
      body:  (<Grid 
        data={edemailData} 
        pageable={false}
        sortable={true}>
        <GridColumn field="Email Type" />
        <GridColumn field="Email" />
        <GridColumn field="Privacy" />
      </Grid>),
    }
  ];
  const handleReposition = (e) => {
    setData(e.value);
  };

  return(
    <Content title='UCLA - Enterprise Directory Data' pageTitle='UCLA - Enterprise Directory Data'>
      <layout-columnwrapper class='col-1'>
        <main-content id='main-content'>
        <iwe-message class="info-normal"> <p> This feature retrieves data using the IAMUCLA Enterprise Directory Web Service </p> 			
 			
 		</iwe-message>
          <div className="row ed-view">
            <form onSubmit={handleSearch}  className="formFields" >
              <div className="k-form-buttons">
                <div className="custom-col1">
                    <TextBox
                      value={value}
                      onChange={handleChange}
                      placeholder="Enter 9-digit UID or UCLA Logon" 
                      suffix={() => (
                        <>
                          {value !== EMPTY_VALUE && (
                            <InputClearValue onClick={handleClear}>
                              <Icon name="x" />
                            </InputClearValue>
                          )}
                        </>
                      )}
                      style={{
                        width: 430,
                      }}
                   />
                </div>
                <Button className="primary" id="btnSearch" themeColor={'info'} fillMode={'solid'} rounded={null} type={"submit"} >
                      <span className="k-icon k-i-search"></span> GO
                </Button>
                <div className="custom-col2">&nbsp;</div>
              </div>
            </form>
          </div>
          <div className="margin-top-30">
          {searching && loadingPanel}
          {(value && edProfileData.length)?(
                <TileLayout
                columns={2}
                rowHeight={600}
                positions={data}
                gap={{
                  rows: 10,
                  columns: 10,
                }}
                items={tiles}
                onReposition={handleReposition}
              />
            )
            :
            ''
          }
          </div>
        </main-content>
      </layout-columnwrapper>
    </Content >      
  )
}

export default Edview