import React, { Component } from 'react'


class Content extends Component {
  render() {
    const { title, pageTitle } = this.props

    if (title) document.title = title

    return (
      <section id='layoutContentArea'>
        {pageTitle && <h2 id='titleText'>{pageTitle}</h2>}
        {this.props.children}
      </section>
    )
  }
}

export default Content