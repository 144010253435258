import {createContext, useContext} from 'react';
import {axios_api_instance} from '../misc/API';
import axios from 'axios';
import jwt_decode from "jwt-decode";

export const AuthContext = createContext();
export function useAuth(){
  return useContext(AuthContext)
}
export const googleLoginServerAuth = (token, onSuccessCallback, onFail) => {
  localStorage.removeItem('auth_jwt_token')
  return axios_api_instance.post('/v1/noauth/google',{'token': token})
    .then(response => {
      var res = response.data
      if(res && res.success && res.data.tok){
        localStorage.setItem('auth_jwt_token', res.data.tok)
        onSuccessCallback()
      }
      else{
        onFail();
      }
    })
    .catch(error => {
      console.log(error);
      onFail()
    })
}
export const getAuthToken = ()=>{
  return localStorage.getItem('auth_jwt_token')
}

export const getStoredToken = ()=>{
  return localStorage.getItem('token')
}

export const decodeToken = (token)=>{
  if (token) {
      let decodedToken = jwt_decode(token);
      if (decodedToken) {
        return decodedToken;
      } else {
        return null;
      }
  } else {
      return null;
  }
}

export const validateToken = (token, onSuccessCallback, onFail) => {
  let axios_api_instance = axios.create({
    baseURL: process.env.REACT_APP_VALIDATE_TOKEN_HOST_URL
  });
  axios_api_instance.defaults.headers.get['Content-Type'] = 'application/json';
  axios_api_instance.defaults.headers.get['Authorization'] = 'Bearer '+token;
  return axios_api_instance.get('/test/v1/global/validate_token')
    .then(response => {
      if(response && response.data && response.data.success){
        onSuccessCallback()
      }
      else{
        onFail();
      }
    })
    .catch(error => {
      console.log(error);
      onFail()
    })
}